import React, { Component } from 'react';
import Link from 'gatsby-link';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: {
        expanded: false,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // this will close the nav menu when navigating from page to page
    if (prevState.nav.expanded === this.state.nav.expanded) {
      this.handleNavClick();
    }
  }

  handleNavClick = () => {
    this.setState({
      nav: {
        expanded: !this.state.nav.expanded,
      },
    });
  };

  render() {
    const { siteTitle } = this.props;
    return (
      <nav
        className="navbar is-transparent is-hidden-print"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand is-hidden-desktop">
            <Link
              className="navbar-item is-uppercase has-text-weight-bold"
              to="/"
            >
              {siteTitle}
            </Link>
            <span
              role="button"
              className={`navbar-burger ${
                this.state.nav.expanded ? 'is-active' : ''
              }`}
              aria-label="menu"
              aria-expanded={this.state.nav.expanded}
              onClick={this.handleNavClick}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </span>
          </div>
          <div
            className={`navbar-menu ${
              this.state.nav.expanded ? 'is-active' : ''
            }`}
          >
            <div className="navbar-start">
              <Link className="navbar-item is-lowercase" to="/about">
                About
              </Link>
            </div>
            <div className="brand">
              <Link
                className="navbar-item is-uppercase has-text-weight-bold is-hidden-touch"
                to="/"
              >
                {siteTitle}
              </Link>
            </div>
            <div className="navbar-end">
              <Link className="navbar-item is-lowercase" to="/cv">
                CV
              </Link>
              <Link className="navbar-item is-lowercase" to="/contact">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
