import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from '../components/header';
import Footer from '../components/footer';
import './index.scss';

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            shortTitle
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'The personal website of software engineer Laura Calabro.',
            },
            {
              name: 'keywords',
              content:
                'laura calabro, full stack developer, software engineer, full stack engineer',
            },
          ]}
        />
        <Header siteTitle={data.site.siteMetadata.shortTitle} />
        {children}
        <Footer />
      </div>
    )}
  />
);
